<template>
  <div class="row">
    <div class="col--10 col--md-12">
      <plan-form v-if="stakingPlan" :heading="`Edit ${stakingPlan.title}`" :edit="true" :plan="stakingPlan" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import * as message from '@/store/support/message'
const PlanForm = () => import('@/components/PlanForm')

export default {
  components: {
    PlanForm
  },

  computed: {
    ...mapState('staking', ['stakingPlan'])
  },

  methods: {
    ...mapActions('staking', ['getStakingPlan'])
  },

  created() {
    this.getStakingPlan(this.$route.params.id)
  },

  beforeRouteLeave(to, from, next) {
    const answer = window.confirm(message.CONFIRM_BEFORE_LEAVE)
    if (answer) {
      next()
    } else {
      next(false)
    }
  }
}
</script>
